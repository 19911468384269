import React from "react";
import PageLinks from "../../components/PageLinks/PageLinks";
import "./who-we-are.scss";
const WhoWeAre = () => {
  return (
    <>
      {" "}
      <div className="page-background">
        <div className=" mission-shadow header-wrapper">
          <div className="col-md-12 about-banner "> </div>
          <div className=" col-md-12 after">
            <center>
              <div
                className="col-md-6 prefooter-writeup {
"
              >
                <h3>WHO WE ARE</h3>
                <p>Empowering change, one purpose-driven step at a time.</p>
              </div>
            </center>
          </div>
        </div>
      </div>
      <PageLinks />
      <div className="col-md-8 offset-md-2 col-sm-10 offset-sm-1 mt3 about-us mobile-padding">
        <h4>Name: Network for Educational Advancement and Development (NEAD)</h4>
        <h3>Background and Focus:</h3>
        <p>
          <strong>Network for Educational Advancement and Development</strong> is an
          international group of scholars interested in Africa’s development. It
          is organized in a network whose intention is to promote, disseminate,
          and stimulate high quality research in economic, management, and
          development studies.
        </p>
        <p>
          The seeds of Managing <strong>Network for Educational Advancement and Development</strong> were planted by Professor Vincent Chidindu Asogwa during the International Conference on Advancing the Continent of Africa through Research organized by National Association of African-American Studies and held at the University of South Africa, Pretoria between July 23rd and 25th 2023. His attendance to the conference was sponsored by the University of Eswatini, Eswatini, where he realized that Africa has barely 1% of the world's global research output and, on average, contributes about 0.4% of its GDP to research and development. It was agreed by the inaugural group that there is lack of research on Africa made by African researchers; hence the need to strengthen research capacities and North-South partnerships.
        </p>
        <p>
          In particular, the network will serve as a meeting and communication
          forum for all its subscribers resident in Africa and elsewhere. It is
          a network for exchange of knowledge on an international level, and
          constitutes machinery allowing for a better dissemination of
          information on research, teaching and outreach. The network also aims
          at the development of relations with all other professional and
          research-oriented networks that are active in the field of economics,
          management and development.
        </p>
        <p>
          <strong>NEAD Vision: </strong>
          Leading catalyst for Africa research, publication and application.
        </p>
        <p>
          <strong>NEAD Mission: </strong>{" "}
          facilitate research, publish scholarly articles, and stimulate
          creativity, innovation and application in Africa based organisations
          to foster socio economic change for the betterment of all.
        </p>

        <h3>Guiding Philosophy</h3>
        <ul>
          <li>None of us is greater than all of us.</li>
          <li>I am because we are; I can only be a person through others.</li>
          <li>Each of us needs all of us.</li>
          <li>An injury to one is an injury to all of us.</li>
          <li>
            Mutual respect is important because my humanity is bound up with
            yours.
          </li>
          <li>The whole affects the part as much as the part affects the whole.</li>
        </ul>
        <h3>Operating Principles</h3>
        <h5>As a result of our guiding philosophy we will:</h5>
        <ul>
          <li>Be open and honest.</li>
          <li>Respect confidentiality.</li>

          <li>Manage agreements.</li>
          <li>Maintain a supportive environment.</li>
          <li>Be self-monitoring.</li>
          <li>
            Hold a proper attitude for learning. (We believe no man or woman has
            monopoly of knowledge and wisdom. We are all specialists in some
            areas, generalists in others and very ignorant in yet other areas.
            We are therefore prepared to learn from any individual or
            situation).
          </li>
          <li>
            Maintain a 1% possibility i.e. even if I think I am almost 100 %
            sure I am right on this view, issue etc. I give myself 1 %
            possibility I could be wrong and therefore I am prepared to listen
            to the other viewpoints from my colleague/s and other interested
            parties.
          </li>
          <li>Listen in order to be listened to.</li>
          <li>Work as a team.</li>
          <li>
            Avoid unhealthy competition but engage in fruitful cooperation.
          </li>
          <li>Avoid jumping to conclusions without hearing all the facts.</li>
          <li>Avoid assumptions but get the facts.</li>
          <li>Build on each other’s ideas.</li>
          <li>Strive for fairness and excellence.</li>
          <li>
            Do what we promised we will do and therefore build our integrity.
          </li>
          <li>Be faithful to our promises.</li>
          <li>Ask if unclear and seek help if we need it.</li>
        </ul>
      </div>
    </>
  );
};

export default WhoWeAre;
